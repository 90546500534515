import React from 'react'
import classNames from 'classnames'
import Logo from 'src/components/icons/Logo'

export function DefaultAvatarLogo({small}) {

    return (
        <div className="relative w-full">
            <div className={classNames("relative bg-grey-300 w-full h-0 pt-full rounded-full")}>
                <div className="absolute inset-0 flex items-center justify-center p-5">
                    <Logo color={'rgba(36, 48, 94, 1)'} />
                </div>
            </div>
        </div>
    )
}
