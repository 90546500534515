import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import { Context as BookingContext } from 'src/context/BookingContext'
import { useTranslation } from 'react-i18next'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import { useVisitorApi } from 'src/hooks/useApi'
import TeacherProfileHero from 'src/components/sections/teacherProfile/Hero'
import Biography from 'src/components/sections/teacherProfile/Biography'
import PriceList from 'src/components/sections/teacherProfile/PriceList'
import MediaList from 'src/components/sections/teacherProfile/MediaList'
import FixedFooter from 'src/components/sections/teacherProfile/FixedFooter'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function TeacherProfile(props) {
    const { t } = useTranslation();
    const { setTheme } = useContext(ThemeContext);
    const { id } = props;
    const { bookTeacher } = useLocalizedRoutes();
    const { state: { teacher }, changeBooking } = useContext(BookingContext);
    const [loading, setLoading] = useState(false);
    const { getSingleTeacher } = useVisitorApi();

    useEffect(() => setTheme('normal'), [])

    async function getTeacher() {
        setLoading(true)
        let { data } = await getSingleTeacher(id)
        changeBooking({ teacher: data.data })
        setLoading(false)
    }

    useEffect(() => {
        getTeacher()
    }, [id])

    function checkIfTeacher() {
        if (teacher) {
            return (
                <>
                    <TeacherProfileHero teacher={teacher}/>
                    <div className="container mx-auto px-4 lg:px-12 xl:px-32 pb-24">
                        <Biography teacher={teacher}/>
                        <PriceList teacher={teacher}/>
                        <MediaList teacher={teacher}/>
                    </div>
                    <FixedFooter onClick={() => navigate(`${bookTeacher}/${teacher.id}?numberOfLessons=1&duration=30&isTrial=0`)}/>
                </>
            )
        }
    }

    return (
        <MarketingLayout {...props} noFooter title={t('teacherProfile.title')}>
            {checkIfTeacher()}
        </MarketingLayout>
    )
}
