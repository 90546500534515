import React, {useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import {navigate} from 'gatsby'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import {usePageContext} from 'src/context/PageContext'
import Heading from 'src/components/typography/Heading'
import Label from 'src/components/forms/Label'
import {DefaultAvatarLogo} from 'src/components/atoms/DefaultAvatar'


const INFO_BOX = "w-1/2 sm:w-1/3 pr-4 text-left py-3";
const TEACHER_INFO = "font-semibold lg:text-xl text-dark";
const DEFAULT = classNames(
    'appearance-none py-3 px-8 relative hover:opacity-90',
    'text-base font-bold font-general',
    'transition-all duration-200 focus:outline-none',
);
const LIGHT = 'bg-white text-dark-blue-600';

export default function TeacherProfileHero({teacher}) {
    const {t} = useTranslation();
    const {state: {profile}} = useContext(ProfileContext);
    const { bookTrial } = useLocalizedRoutes();
    const {lang} = usePageContext();
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        setImgError(false)
    }, [teacher]);

    function getYears(date) {
        let startedTeaching = DateTime.fromISO(date, {zone: 'gmt'});
        let today = DateTime.local();
        let {values} = today.diff(startedTeaching, ['years']);

        return Math.floor(values.years) <= 5 ?  Math.floor(values.years) : '5+';
    }

    function translateLanguages(lang) {
        const idiom = t(`findTeacher.filters.teaches_in_languages`, {returnObject: true}).find(idiom => Object.keys(idiom)[0].toLowerCase() === lang);
        return Object.values(idiom).toString()
    }


    function renderBookingButtons() {
        if(!profile?.teacher_profile) {
            return (
                <div className="flex md:self-start items-center flex-col md:flex-row mt-4 lg:mt-8">
                    <a
                        href="#price-list"
                        className={classNames(DEFAULT, LIGHT)}
                    >{t('teacherProfile.book')} {teacher.first_name} {t('teacherProfile.now')}</a>
                </div>
                )
        }
    }

    return (
        <div className="px-4 bg-baby-blue-600 py-10 lg:py-20 w-full">
            <div className="mx-auto w-full lg:px-12 xl:px-40 container text-center flex flex-wrap md:flex-no-wrap items-center">
                {teacher &&
                <div className="w-32 h-32 sm:h-40 sm:w-40 lg:h-56 lg:w-56 mx-auto flex-grow-0 flex-shrink-0">
                    {imgError ? (
                        <DefaultAvatarLogo />
                    ) : (
                        <img
                            src={teacher.profile_picture_url}
                            onError={(error) => {
                                setImgError(true)
                            }
                            }
                            alt={teacher.first_name}
                            className="object-cover rounded-full w-full h-full"
                        />
                    )}
                </div>
                }
                <div className="w-full md:w-auto lg:flex-1">
                    <div className="flex flex-col items-center md:items-start pt-4 lg:pt-0 md:pl-12 lg:pl-24 w-full">
                        <Heading type="sub-title" className="text-dark-blue-600 text-left xl:mb-4">
                            {teacher.first_name} {teacher.last_name}
                        </Heading>
                        <div className="flex w-full flex-wrap mt-4">
                            <div className={classNames(INFO_BOX)}>
                                <Label>{t('teacherProfile.teachingExperience')}</Label>
                                <p className={classNames(TEACHER_INFO)}>
                                    {getYears(teacher.teaching_since)} {t('teacherProfile.years')}
                                </p>
                            </div>
                            <div className={classNames(INFO_BOX)}>
                                <Label>{t('teacherProfile.instrument')}</Label>
                                <p className={classNames(TEACHER_INFO)}>
                                    {teacher.instruments.map((inst, i) => (
                                        <span className="capitalize" key={i}>
                                            {inst.translations[lang]}{teacher.instruments.length - 1 === i ? '' : ', '}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            <div className={classNames(INFO_BOX)}>
                                <Label>{t('teacherProfile.languages')}</Label>
                                <p className={classNames(TEACHER_INFO)}>
                                    {teacher.teaches_in_languages.map((lang, i) => (
                                        <span className="capitalize" key={i}>{translateLanguages(lang)}
                                            {teacher.teaches_in_languages.length - 1 === i ? '' : ', '}
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                        {renderBookingButtons()}
                    </div>
                </div>
            </div>
        </div>
    )
}
