import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import MediaVideo from 'src/components/interactivity/MediaVideo'

export default function MediaList({ teacher : { video_links }}) {
    const { t } = useTranslation();

    function YouTubeGetID(url){
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    function checkIfMedia() {
        if(!video_links.length) return <p>{t('teacherProfile.noMedia')}</p>
        else return (
            <div className="flex flex-wrap -mx-2 mt-4 lg:mt-0">
                {video_links.map((videoId,index) => (
                    <MediaVideo key={index} youtubeId={YouTubeGetID(videoId)}/>
                ))}
            </div>
        )
    }

    return (
        <div className="my-10 lg:my-24 lg:mb-32 flex flex-wrap">
            <div className="w-full lg:w-4/12">
                <Heading type="sub-title" className="capitalize">{t('teacherProfile.media')}</Heading>
            </div>
            <div className="w-full lg:w-8/12">
                {checkIfMedia()}
            </div>
        </div>
    )
}
