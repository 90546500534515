import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'src/context/PageContext'
import Heading from 'src/components/typography/Heading'

export default function Biography({teacher}) {
    const { t } = useTranslation();
    const { lang } = usePageContext();

    return (
        <div className="mx-auto my-10 lg:my-24 flex flex-wrap">
            <div className="w-full lg:w-4/12">
                <Heading type="sub-title" className="capitalize pb-4">{t('teacherProfile.biography')}</Heading>
            </div>
            <div className="w-full lg:w-8/12 lg:pt-4">
                <p className="text-grey-600">{teacher.bio[lang] ? teacher.bio[lang] : t('teacherProfile.biographyNotAvailable')}</p>
            </div>
        </div>
    )
}
