import React from 'react'
import classNames from 'classnames'

const RESP_CONTAINER = "relative pt-16/9 h-0";
const RESP_IFRAME = "absolute inset-0 w-full h-full";

export default function MediaVideo({ youtubeId }) {
    return (
        <div className="w-full md:w-6/12 mb-4 px-2">
            <div className={classNames(RESP_CONTAINER)}>
                <iframe
                    className={classNames(RESP_IFRAME)}
                    gesture="media"
                    allow="encrypted-media"
                    allowFullScreen
                    src={`https://www.youtube.com/embed/${youtubeId}`}
                />
            </div>
        </div>
    )
}
