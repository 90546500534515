import React, {useContext, useEffect, useState} from 'react'
import {navigate} from 'gatsby'
import {useTranslation} from 'react-i18next'
import {Context as BookingContext} from 'src/context/BookingContext'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import {usePageContext} from 'src/context/PageContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import Heading from 'src/components/typography/Heading'
import PrimaryButton, {OutlinedButton, SubmitButton} from 'src/components/buttons/Primary'
import {DefaultAvatarLogo} from 'src/components/atoms/DefaultAvatar'

export default function FixedFooter({onClick, showNextButton, completeBooking, loading}) {
    const {t} = useTranslation();
    const {teacherProfile, bookTeacher, bookingDetailsReview, bookTrial, editStudentAccount} = useLocalizedRoutes();
    const {lang, paths} = usePageContext();
    const {state: {nrOfLessons, price, teacher}} = useContext(BookingContext);
    const {state: {profile}} = useContext(ProfileContext);
    const [showPrimaryButton, setShowPrimaryButton] = useState(true);
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        if (showNextButton !== null && showNextButton !== undefined) {
            setShowPrimaryButton(!!showNextButton)
        }
    }, [showNextButton])


    function inBookingDetailsPage() {
        return paths[lang] === `/${bookingDetailsReview.split('/').splice(2,2).join("/")}`
    }

    function renderPriceDetails() {
        const trialEnabled = teacher ? teacher.lesson_plans.find((plan) => plan.is_trial === true) : false;
        if (!price && !profile?.teacher_profile && trialEnabled) return (
            <OutlinedButton
                className="hidden md:block mr-2"
                onClick={() => navigate(`${bookTeacher}/${teacher.id}?numberOfLessons=${1}&duration=30&isTrial=${trialEnabled ? '1' : '0'}`)}
            >
                {t('teacherProfile.book')} {t('teacherProfile.trial')}
            </OutlinedButton>
        )
        if(price) {
            return (
                <div className="mr-3 mb-2 md:mb-0 text-xl md:text-center">
                    <span>€ {(price.discounted_total_price_for_students.value)} </span>
                    <span className="text-grey-600">
                        {t('bookATeacher.for')} {nrOfLessons} {nrOfLessons > 1 ? t('bookATeacher.lessons').toLowerCase() : t('bookATeacher.lesson').toLowerCase()}
                    </span>
                </div>
            )
        }
        return null
    }

    function renderButton() {
        if(showPrimaryButton && !profile?.teacher_profile){
            if(inBookingDetailsPage()) {
                if(!profile) {
                    return (
                        <SubmitButton className="flex-grow md:flex-grow-0" onClick={completeBooking}>
                            {t('bookATeacher.completeBooking')}
                        </SubmitButton>
                    )
                } else if(profile.email_verified_at) {
                    return (
                        <SubmitButton className="flex-grow md:flex-grow-0" onClick={completeBooking}>
                            {t('bookATeacher.completeBooking')}
                        </SubmitButton>
                    )
                }

                return null
            } else {
                if(teacher ? teacher.lesson_plans.length > 0 : false) {
                    return (
                        <PrimaryButton className="flex-grow md:flex-grow-0" onClick={onClick} isLoading={loading}>
                            {`${t('teacherProfile.book')} ${teacher.first_name} ${t('teacherProfile.now')}`}
                        </PrimaryButton>
                    )
                } else {
                    return null
                }
            }
        }
    }

    return (
        <div className="fixed left-0 py-4 bottom-0 w-full border-t border-grey-300 bg-white px-4">
            <div className="flex justify-between container mx-auto">
                <div className="hidden md:flex items-center cursor-pointer flex-grow" onClick={() => navigate(`${teacherProfile}/${teacher.id}`)}>
                    <div className="w-16">
                        {imgError ?
                            <DefaultAvatarLogo small/> :
                            <img className="avatar"
                                 src={teacher.profile_picture_url}
                                 onError={() => setImgError(true)}
                                 alt={teacher.first_name}
                            />
                        }
                    </div>
                    <div className="pl-4">
                        <Heading type="caption">
                            {teacher.first_name} {teacher.last_name}
                        </Heading>
                        <div className="text-grey-600">
                            {teacher.instruments.map((inst, i) => (
                                <span className="capitalize"
                                      key={i}>
                                    {inst.translations[lang]}{teacher.instruments.length - 1 === i ? '' : ', '}
                                </span>
                            ))}
                            <span> {t('teacherProfile.lessons')}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-grow md:items-center md:flex-grow-0">
                    {renderPriceDetails()}
                    {renderButton()}
                </div>
            </div>
        </div>
    )
}
