import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import TeacherPriceList from 'src/components/tables/TeacherPriceList'
import useWindowSize from 'src/hooks/useWindowSize'

export default function PriceList({teacher}) {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const size = width >= 768 ? 'large' : 'small';

    return (
        <div className="my-10 lg:my-24 flex flex-wrap" id="price-list">
            <div className="w-full lg:w-4/12">
                <Heading type="sub-title" className="capitalize pb-4">{t('teacherProfile.price')}</Heading>
            </div>
            <div className="w-full lg:w-8/12">
                <TeacherPriceList teacher={teacher} screenSize={size}/>
            </div>
        </div>
    )
}
