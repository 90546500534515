import React, { useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { navigate, Link } from 'gatsby'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

const DEFAULT = 'border-t border-grey-300 border-b px-4 md:px-6 py-6';
const FIRST_COLUMN = 'text-lg font-bold';
const LAST_COLUMN = 'text-dark-blue-600 cursor-pointer transition-all duration-200 hover:underline';
const MIDDLE_COLUMN = 'text-grey-600';
const ROW = 'cursor-default hover:bg-grey-300';
const DETAILS = "flex text-grey-600 text-sm";

const DURATION = 30;

function TeacherPriceList ({ teacher: { id, lesson_plans }, screenSize }) {
    const { t } = useTranslation();
    const {state: {profile}} = useContext(ProfileContext);
    const { bookTeacher } = useLocalizedRoutes();

    const lessons = lesson_plans ? lesson_plans.filter(lesson => lesson.lesson_duration_minutes === DURATION) : [];
    const trial = lesson_plans.find(lesson => lesson.is_trial === true);

    function bookingLink(trial, numberOfLessons){
        if(!profile?.teacher_profile) {
            return (
                <td className={classNames(DEFAULT, LAST_COLUMN)}>
                    <Link className="font-semibold underline" to={`${bookTeacher}/${id}?numberOfLessons=${numberOfLessons}&duration=${DURATION}&isTrial=${trial ? '1' : '0'}`}>{t('teacherProfile.book')} {t('teacherProfile.now')}</Link>
                </td>
            )
        }
    }

    function rowTitle (lessonsNr) {
        switch (lessonsNr) {
            case 0:
                return t('teacherProfile.trial');
            case 1:
                return `${lessonsNr} ${t('teacherProfile.lesson')}`
            default:
                return `${lessonsNr} ${t('teacherProfile.lessons')}`
        }
    }

    const propsList = { t, lessons, bookingLink, rowTitle, trial };

    return screenSize === 'large' ?
        <LargeList {...propsList}/> :
        <SmallList {...propsList} />
}

function LargeList (props) {
    const { t, lessons, bookingLink, rowTitle, trial } = props;
    return (
        <table className="table-auto border border-grey-300 rounded w-full">
            <tbody>
            {trial ?
                <tr className={classNames(ROW)} >
                    <td className={classNames(DEFAULT, FIRST_COLUMN, 'capitalize')}>
                        {t('teacherProfile.trial')}
                    </td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}>
                        &euro;&nbsp;{(+trial.lesson_price_for_students.value).toFixed(2)}
                    </td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}>
                        {DURATION} {t('general.time.min')}
                    </td>
                    {bookingLink(true, 1)}
                </tr> : null
            }
            {lessons.map((priceRow, index) => (
                <tr className={classNames(ROW)} key={index}>
                    <td className={classNames(DEFAULT, FIRST_COLUMN, 'capitalize')}>
                        {rowTitle(priceRow.lesson_amount)}
                    </td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}>
                        {t('general.time.from')} &euro;&nbsp;{(+priceRow.discounted_total_price_for_students.value).toFixed(2)}
                    </td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}>
                        {`${DURATION} ${t('general.time.min')} / 1 ${t('general.time.hour')}`}
                    </td>
                    {bookingLink(false, priceRow.lesson_amount)}
                </tr>
            ))}
            {(!trial && lessons.length === 0) &&
                <tr className={classNames(ROW)}>
                    <td className={classNames(DEFAULT, FIRST_COLUMN, 'capitalize')}>
                        {t('general.time.comingSoon')}
                    </td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}></td>
                    <td className={classNames(DEFAULT, MIDDLE_COLUMN)}></td>
                </tr>
            }
            </tbody>
        </table>
    )
}

function SmallList (props) {
    const { t, lessons, bookingLink, rowTitle, trial } = props;

    return (
        <table className="table-auto border border-grey-300 rounded w-full">
            <tbody>
            {trial ?
                <tr className={classNames(ROW)}>
                    <td className={classNames(DEFAULT)}>
                        <span className={classNames(FIRST_COLUMN)}>
                            {t('teacherProfile.trial')}
                        </span>
                        <div className={classNames(DETAILS)}>
                            {DURATION} {t('general.time.min')}{' '}
                            &euro;&nbsp;{(+trial.lesson_price_for_students.value).toFixed(2)}
                        </div>
                    </td>
                    {bookingLink(true, 1)}
                </tr> : null
            }
            { lessons.map((priceRow, index) => (
                <tr className={classNames(ROW)} key={index}>
                    <td className={classNames(DEFAULT)}>
                    <span className={classNames(FIRST_COLUMN)}>
                        {rowTitle(priceRow.lesson_amount)}
                    </span>
                        <div className={classNames(DETAILS)}>
                            {t('findTeacher.startingFrom')} &euro;&nbsp;{(+priceRow.discounted_total_price_for_students.value).toFixed(2)}
                        </div>
                    </td>
                    {bookingLink(false, priceRow.lesson_amount)}
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default TeacherPriceList

